import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useForm } from "react-hook-form"
import { Button, Card, CardContent, TextField  } from "@material-ui/core"
import { navigate } from "gatsby"

const Styled = styled.div`
 .title {
    width: 100%;
    padding: '0px 70px';
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

const StyledTextField = styled(TextField)`
  ${props =>
        css`
      input {
        color: #000 !important;
      }
      div:after, div:before {
        border-bottom: none;
      }
      div {
        color: #000 !important;
        background-color: #ffffff;
        box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        &:hover {
            background-color: #ffffff;
            box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        }
      }
      label {
        color: #000000 !important;
        font-family: Raleway;
      }
      textarea {
        color: #000 !important;
      }
    `}
`
const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const LoginForm = () => {
 
    const [wrongLogin, setWrongLogin] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [codigoExpired, setCodigoExpired] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
   
    useEffect(() => {
        if (emailSent) {
            reset({
                loginCode: "",
            });
        }
    }, [emailSent, reset]);

    const handleEmail = async (emailData) => {
        try {
            setLoading(true);
            setCodigoExpired(false)
            const currentEmail = emailData || email;

            const response = await fetch(`${URL}/login`, {  //Este endpoint se ocupa de validar si el mail esta ok, en cuyo caso le envia el codigo de ingreso al usuario
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mail_login: currentEmail })
            });

            const responseData = await response.json();

            if(!responseData.ok){
                console.error('Error al enviar el mail: ', responseData);
                if(responseData.error === 'Email no autorizado.'){
                    setEmailError('Email no autorizado.')
                }
                //Si el mail no es valido (no esta en la DB), se debe mostrar un mensaje de error que indique "usuario no cuenta con los permisos"...
                return;
            }
            // console.log('Código de ingreso:', responseData.data.loginCode);
            setEmailSent(true);
            setEmailError('')
        } catch (error) {
            // console.error('Error al generar el codigo de ingreso:', error);
            setEmailError('Error al generar el código de ingreso.');
        }finally{
            setLoading(false);
        }
    };

    const handleLogin = async (data) => {
        try {
            setLoading(true);
            const requestData = {
                ...data,   
                mail_login: email
            };

            const response = await fetch(`${URL}/login/validarCodigo`, {  //Enviamos al Back el codigo de ingreso del usuario
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            const responseData = await response.json();

            if(!responseData.ok){ //Si el codigo no es valido, se debe mostrar un mensaje de error que indique "codigo incorrecto"...
                // console.error('Error al validar el codigo de ingreso: ', responseData);
                if(responseData.error === 'Codigo incorrecto.'){
                    setWrongLogin('El código es incorrecto.')
                }
                if(responseData.error === 'Sesion caducada.'){
                    setWrongLogin('El código expiró.')
                    setCodigoExpired(true)
                }
                return;
            }
                        
            localStorage.setItem('token', responseData.data);
            setWrongLogin('')
            navigate('/encuesta-de-satisfaccion/encuesta-opciones') //Si el codigo es valido, se redirige al usuario a la encuesta
 
        } catch (error) {
            // console.error('Error al iniciar sesión:', error);
            setWrongLogin('Error al iniciar sesión.')
        }finally{
            setLoading(false);
        }
    };
    return (
        <Styled>
            <div style={{ textAlign: 'center', padding: '2rem' }}>
                <h2 style={{ fontSize: '2rem', margin: '0.5rem 0' }}>Login</h2>
                <h4 style={{ color: 'grey', fontSize: '1.4rem', marginBottom: '0' }}>
                    {emailSent ? "Ingrese el código recibido para iniciar sesión.": "Ingrese su email para recibir un código de acceso."}
                </h4>
            </div>
            <div className="ContactForm" style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ width: '100%', maxWidth: '450px', padding: '1rem', marginBottom: '2rem' }}>
                    <CardContent>
                        {!emailSent ? (
                            <form
                                className="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit((data) => {
                                    setEmail(data.mail_login); 
                                    handleEmail(data.mail_login);
                                })}
                            >
                                <div className="formControl" style={{ display:'flex', flexDirection:'column' }}>
                                    <StyledTextField
                                        label='Email'
                                        className="form-field"
                                        inputRef={register({
                                            required: true,
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@globalhitss\.com$/i,
                                                message: 'Ingrese un correo válido'
                                            },
                                        })}
                                        name='mail_login'
                                        id='mail_login'
                                        placeholder='Escribe aquí.'
                                        size="small"
                                        inputProps={{ maxLength: 50 }}
                                        variant="filled"
                                        fullWidth={true}
                                        type="email"          
                                        // helperText={errors['mail_login'] ? errors['mail_login'].message : ''}
                                        // error={errors['mail_login'] ? true : false}
                                        helperText={emailError || (errors['mail_login'] ? errors['mail_login'].message : '')}
                                        error={!!emailError || (errors['mail_login'] ? true : false)}
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth={true}
                                    className="submit"
                                    style={{ backgroundColor: '#269094', marginTop: '5%' }}
                                >
                                    {loading ? "Cargando..." : "Generar código"}
                                </Button>
                            </form>
                        ) : (
                            <form
                                className="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit(handleLogin)}
                            >
                                <div className="formControl" style={{ display:'flex', flexDirection:'column' }}>
                                    <StyledTextField
                                        label='Clave generada'
                                        className="form-field"
                                        inputRef={register({
                                            required: 'La clave es obligatoria',
                                        })}
                                        type="password"
                                        name='loginCode'
                                        id='loginCode'
                                        placeholder='Escribe aquí.'
                                        size="small"
                                        variant="filled"
                                        fullWidth={true}
                                        helperText={wrongLogin || (errors['loginCode'] ? errors['loginCode'].message : wrongLogin ? 'Credenciales incorrectas' : '')}
                                        error={!!wrongLogin || (errors['loginCode'] || wrongLogin)}
                                    />
                                </div>
                                {codigoExpired 
                                    ? 
                                        <Button 
                                            onClick={()=>handleEmail(email)}
                                            style={{ color: '#269094', fontSize: '0.8rem', padding: '0.2rem', cursor:'pointer', backgroundColor: 'transparent', textDecoration: 'underline' }}
                                            >Solicitar un nuevo código.
                                        </Button>
                                    : ''
                                }
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth={true}
                                    className="submit"
                                    style={{ backgroundColor: '#269094', marginTop: '5%' }}
                                >
                                    {loading ? "Cargando..." : "Iniciar sesión"}
                                </Button>
                            </form>
                        )}
                    </CardContent>
                </Card>
            </div>
        </Styled>
    );
}
 
export default LoginForm;